<template>
  <div id="app">
    <div class="p-4 pt-10">
      <TheHeader @selectedDateChange="changeSelectedDate" />
      <InputToken v-if="!isToken" @closeInput="isToken = true" />
      <TodoContent v-if="isToken" :selected-date="selectedDate" />
      <!--      <LoadingSpinner v-if="loading"/>-->
    </div>
  </div>
</template>

<script>
import TheHeader from './components/TheHeader';

// import LoadingSpinner from "./components/LoadingSpinner";
import InputToken from './components/InputToken';
import TodoContent from './components/TodoContent';

export default {
  name: 'App',
  components: {
    TodoContent,
    InputToken,
    TheHeader,
  },
  data() {
    return {
      getToken: false,
      componentKey: 0,
      selectedDate: new Date(),
      isToken: true,
    };
  },
  methods: {
    changeSelectedDate(date) {
      this.selectedDate = date;
    },
    forceRerender() {
      this.getToken = false;
      this.componentKey += 1;
      console.log(this.componentKey);
    },
  },
  created() {
    if (localStorage.getItem('myToken')) {
      this.isToken = true;
    } else {
      this.isToken = false;
    }
  },
};
</script>

<style scoped></style>
