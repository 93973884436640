<template>
  <div
    class="px-3 h-6 rounded-full text-xs font-semibold flex items-center bg-gray-100"
    :style="{ color: colorCode }"
  >
    <span
      class="w-2 h-2 rounded-full mr-1"
      :style="{ backgroundColor: colorCode }"
    ></span>
    <span>
      <slot></slot>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    colorCode: {
      type: String,
      default: "teal",
    },
  },
};
</script>

<!--:class="`bg-${color}-100 text-${color}-700`"-->
