import axios from 'axios';
// const token = "a0344bfb95715a49677dc937222be0b65de42a93";

// let token = localStorage.getItem('myToken')
// console.log(token)

const apiClient = axios.create({
  baseURL: 'https://api.todoist.com/rest/v1/',
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: {
      toString() {
        return `Bearer ${localStorage.getItem('myToken')}`;
      },
    },
  },
});

// apiClient.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   let authKey = store.getters.getAuthKey
//   config.headers["Authorization"] = "Basic " + authKey;
//   return config;
// });

export default {
  getTasks() {
    return apiClient.get('/tasks');
  },
  getProjects() {
    return apiClient.get('/projects');
  },
};
