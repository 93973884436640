<template>
  <div class="flex justify-center">
    <LoadingSpinner v-if="loading" />
    <div v-else class="min-h-screen flex overflow-x-scroll py-12 px-4">
      <!--      OverDue Tasks-->
      <div
        v-if="overDueTasks.tasks.length"
        class="bg-red-100 rounded-lg px-3 py-3 column-width rounded mr-4"
      >
        <div
          class="flex justify-between text-gray-700 font-semibold font-sans tracking-wide text-sm"
        >
          <p>Overdue Tasks</p>
          <span
            >Suma:<span class="text-indigo-800"
              >T:{{ overDueTasks.totalTime }}</span
            ></span
          >
        </div>
        <div>
          <task-card
            v-for="(task, idx) in overDueTasks.tasks"
            :key="idx"
            :task="task"
            class="mt-3"
          ></task-card>
        </div>
      </div>

      <!--      Week tasks-->
      <div
        v-for="(weekday, idx) in sortedWeekTask"
        :key="weekday.name"
        class="bg-gray-100 rounded-lg px-3 py-3 column-width rounded mr-4"
        :class="{ 'bg-indigo-100': ++idx === selectedDate.getDay() }"
      >
        <div
          class="flex justify-between text-gray-700 font-semibold font-sans tracking-wide text-sm"
        >
          <p>
            {{ weekday.name }}
          </p>
          <span
            >Suma:
            <span class="text-indigo-800">T:{{ weekday.totalTime }}</span></span
          >
        </div>

        <div>
          <task-card
            v-for="(task, idx) in weekday.tasks"
            :key="idx"
            :task="task"
            class="mt-3"
          ></task-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TaskCard from './TaskCard';
import TasksService from '../services/TasksService';
import { getDay, getWeek, parseISO } from 'date-fns';
import LoadingSpinner from './LoadingSpinner';

export default {
  name: 'TodoContent',
  components: { LoadingSpinner, TaskCard },
  props: ['selectedDate'],
  data() {
    return {
      tasks: [],
      weekDays: [
        'Pondělí',
        'Úterý',
        'Středa',
        'Čtvrtek',
        'Pátek',
        'Sobota',
        'Neděle',
      ],
      loading: false,
    };
  },
  computed: {
    sortedWeekTask() {
      return this.weekDays.map((day, index) => {
        return {
          name: day,
          tasks: this.getDayTasks(index),
          totalTime: this.getTotalTime(index),
        };
      });
    },
    overDueTasks() {
      return {
        tasks: this.getOverDueTasks(),
        totalTime: this.getTotalOverDueTime(),
      };
    },
    getWeekTasks() {
      return this.tasks.filter(
        (task) =>
          getWeek(parseISO(task.due.date), { weekStartsOn: 1 }) ===
          getWeek(this.selectedDate, { weekStartsOn: 1 })
      );
    },
  },
  mounted() {
    this.loading = true;
    TasksService.getTasks().then((tasks) => {
      TasksService.getProjects().then((projects) => {
        const regex = /T:\s?(\d+)/;

        this.tasks = tasks.data
          .filter((task) => {
            return task.due;
          })
          .map((task) => {
            let taskProject = projects.data.find(
              (project) => project.id === task.project_id
            );
            return {
              ...task,
              project: { name: taskProject.name, color: taskProject.color },
              statedTime: parseInt(
                task.content.match(regex) ? task.content.match(regex)[1] : 0
              ),
            };
          });
        this.loading = false;
      });
    });
  },
  methods: {
    getDayTasks(index) {
      return this.getWeekTasks
        .filter((task) => {
          return getDay(parseISO(task.due.date)) === index + 1;
        })
        .sort((a, b) => (a.priority > b.priority ? 1 : -1));
    },
    getTotalTime(index) {
      let totalTime = this.getDayTasks(index).reduce((total, task) => {
        return total + task.statedTime;
      }, 0);
      return Math.round(totalTime / 60);
    },
    getOverDueTasks() {
      return this.tasks.filter(
        (task) =>
          getWeek(parseISO(task.due.date), { weekStartsOn: 1 }) <
          getWeek(new Date(), { weekStartsOn: 1 })
      );
    },
    getTotalOverDueTime() {
      let totalTime = this.getOverDueTasks().reduce((total, task) => {
        return total + task.statedTime;
      }, 0);
      return Math.round(totalTime / 60);
    },
  },
};
</script>

<style scoped>
.column-width {
  min-width: 320px;
  width: 320px;
}
</style>
