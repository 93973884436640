<template>
  <div
    class="calendar-date-selector flex items-center text-gray-700 font-semibold font-sans tracking-wide text-sm"
  >
    <span @click="selectPrevious">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
        />
      </svg>
    </span>
    <span @click="selectCurrent" class="text-indigo-800 mx-5">Today</span>
    <span @click="selectNext">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4 hover:text-indigo-800"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M13 5l7 7-7 7M5 5l7 7-7 7"
        />
      </svg>
    </span>
  </div>
</template>

<script>
import { addWeeks, subWeeks } from "date-fns";

export default {
  name: "CalendarDateSelector",

  props: {
    // allows us to come back to current week when the “Today” button is clicked.
    currentDate: {
      type: Date,
      required: true,
    },
    modelValue: {
      type: Date,
      required: true,
    },
  },

  methods: {
    selectPrevious() {
      let newSelectedDate = subWeeks(this.modelValue, 1);
      this.$emit("update:modelValue", newSelectedDate);
    },

    selectCurrent() {
      let newSelectedDate = this.currentDate;
      // this.$emit("dateSelected", newSelectedDate);
      this.$emit("update:modelValue", newSelectedDate);
    },

    selectNext() {
      let newSelectedDate = addWeeks(this.modelValue, 1);
      this.$emit("update:modelValue", newSelectedDate);
    },
  },
};
</script>

<style scoped></style>
