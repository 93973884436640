<template>
  <div
    class="calendar-date-indicator mr-10 text-gray-700 font-semibold font-sans tracking-wide text-sm"
  >
    {{ selectedWeekDays.firstDay }}
    <span class="font-bold text-indigo-800">&mdash;</span>
    {{ selectedWeekDays.lastDay }}
  </div>
</template>

<script>
import { endOfWeek, format, getWeek, startOfWeek } from "date-fns";

export default {
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
  },

  computed: {
    selectedWeek() {
      // return this.selectedDate.format("MMMM YYYY");
      return getWeek(this.selectedDate);
    },
    selectedWeekDays() {
      return {
        firstDay: format(
          startOfWeek(this.modelValue, { weekStartsOn: 1 }),
          "do"
        ),
        lastDay: format(
          endOfWeek(this.modelValue, { weekStartsOn: 1 }),
          "do MMM yyy"
        ),
      };
    },
  },
};
</script>

<style scoped></style>
