<template>
  <div
    class="flex flex-col justify-between bg-white shadow rounded px-3 pt-3 pb-5 border-2 border-white minHeight text-gray-700 font-semibold font-sans tracking-wide text-sm overflow-auto"
    :class="task.statedTime === 0 ? 'border-red-600' : ''"
    :style="{ height: task.statedTime + 'px' }"
  >
    <slot>
      <div class="flex justify-between">
        <div class="taskLink" v-html="taskUrl" v-link-new-window></div>
        <a
          :href="task.url"
          target="_blank"
          class="text-indigo-400 hover:text-indigo-800 ml-1"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
            />
          </svg>
        </a>
      </div>

      <div class="flex justify-between mt-4 items-center">
        <badge v-if="task.project" :color-code="badgeColor">{{
          task.project.name
        }}</badge>
        <span :class="task.statedTime === 0 ? 'text-red-700' : ''"
          >T:{{ taskTimeInHours }}</span
        >
      </div>
    </slot>
  </div>
</template>
<script>
import { marked } from 'marked';
import Badge from './Badge.vue';

export default {
  components: {
    Badge,
  },
  props: {
    task: {
      type: Object,
      default: () => ({}),
    },
  },
  directives: {
    linkNewWindow: {
      mounted(el) {
        const anchors = el.querySelectorAll('a');
        anchors.forEach((anchor) => (anchor.target = '_blank'));
      },
    },
  },
  computed: {
    badgeColor() {
      const mappings = {
        30: '#b8256f',
        31: '#db4035',
        32: '#ff9933',
        33: '#fad000',
        34: '#afb83b',
        35: '#7ecc49',
        36: '#299438',
        37: '#6accbc',
        38: '#158fad',
        39: '#14aaf5',
        40: '#96c3eb',
        41: '#4073ff',
        42: '#884dff',
        43: '#af38eb',
        44: '#eb96eb',
        45: '#e05194',
        46: '#ff8d85',
        47: '#808080',
        48: '#b8b8b8',
        49: '#ccac93',
        default: 'teal',
      };
      return mappings[this.task.project.color] || mappings.default;
    },
    taskName() {
      const reg = /(\bT:[0-9]*\b)(?!.*\1)/;
      return this.task.content.replace(reg, '').trim();
    },
    taskUrl() {
      return marked(this.taskName);
    },
    taskTimeInHours() {
      // return Math.round(((this.task.statedTime / 60) + Number.EPSILON) * 100) / 100;
      return (
        Math.round((this.task.statedTime / 60 + Number.EPSILON) * 100) / 100
      );
    },
  },
};
</script>

<style>
.minHeight {
  min-height: 130px;
}
</style>
