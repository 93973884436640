<template>
  <form class="flex items-center justify-center mt-10 gap-5">
    <div
      class="max-w-sm w-80 relative border border-gray-300 rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-indigo-600 focus-within:border-indigo-600"
    >
      <label
        for="token"
        class="absolute -top-2 left-2 -mt-px inline-block px-1 bg-white text-xs font-medium text-gray-900"
      >
        Token
      </label>
      <input
        type="text"
        name="token"
        id="token"
        class="block w-full border-0 p-0 text-gray-900 placeholder-gray-500 focus:ring-0 sm:text-sm"
        placeholder="Your Token"
        v-model="token"
      />
    </div>
    <button
      @click="setToken"
      type="submit"
      class="px-4 py-2 bg-indigo-800 text-white rounded"
    >
      Set Token
    </button>
  </form>
</template>

<script>
export default {
  name: "InputToken",
  emits: ["closeInput"],
  data() {
    return {
      token: null,
    };
  },
  methods: {
    setToken() {
      localStorage.setItem("myToken", this.token);
      this.$emit("closeInput");
    },
  },
};
</script>

<style scoped></style>
