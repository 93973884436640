<template>
  <div class="flex justify-between items-center">
    <div
      class="flex justify-between items-start gap-5 max-w-lg bg-gray-50 mx-auto py-5 px-5 rounded shadow whitespace-nowrap"
    >
      <CalendarDateIndicator
          v-model="selectedDate"
      />
      <CalendarDateSelector
        v-model="selectedDate"
        :current-date="today"
        @dateSelected="selectDate"
      />
    </div>
  </div>
</template>

<script>
import CalendarDateIndicator from "./CalendarDateIndicator";
import CalendarDateSelector from "./CalendarDateSelector";

export default {
  name: "TheHeader",
  emits: ["selectedDateChange"],
  components: { CalendarDateSelector, CalendarDateIndicator },
  data() {
    return {
      //currently selected date (set to today’s date by default)
      selectedDate: new Date(),
      today: new Date(),
    };
  },
  methods: {
    selectDate(newSelectedDate) {
      this.selectedDate = newSelectedDate;
    },
  },
  watch: {
    selectedDate(newValue) {
      this.$emit("selectedDateChange", newValue);
    },
  },
};
</script>
